*,
*:before,
*:after {
  box-sizing: border-box;
}

.title-section {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #000;
    margin-bottom: 20px;
}
.subtext-section {
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    text-align: justify;
    text-justify: inter-word;
    color: #000;
}
.web-title {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    margin-bottom: 30px;
}
.boxRecursos{
    width: 100%;
    max-width: 1160px;
    height: auto;
    margin: 0px auto;
    display: flex;
    align-items: center;
    box-shadow: 2px 2px 8px 8px rgba(64, 82, 218, 0.1);
    border-radius: 10px;
    background-color: #ffffff;
    padding: 70px;
}
.imgRecursos{
    width: 300px;
    height: auto;
}
.contRecursos{
    width: calc(100% - 300px);
    height: auto;
    padding-left: 40px;
    text-align: center;
}
.contRecursos h3{
    font-weight: 700;
    font-size: 48px;
    line-height: 55px;
    color: #404040;
    margin-bottom: 20px;
}
.contRecursos p{
    font-weight: 400;
    font-size: 28px;
    line-height: 32px;
    color: #000000;
    margin-bottom: 45px;
}
.contRecursos a, .contRecursosBtn{
    width: 100%;
    max-width: 530px;
    height: 64px;
    background: #4052DA;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.13);
    border-radius: 7px;
    color: #ffffff !important;
    text-decoration: none;
    font-weight: 500;
    font-size: 28px;
    display: block;
    margin: 0 auto;
    line-height: 64px;
    transition: transform .3s;
    text-align: center;
}
.contRecursos a:hover, .contRecursosBtn:hover{
  transform: scale(1.05);
}
.visibility{
    visibility: hidden;
    height: 1px;
    overflow: hidden;
    display: block;
}

@media screen and (max-width: 1550px){
    .title-section, .web-title {
        font-size: 30px;
        line-height: 40px;
    }
    .subtext-section {
        font-size: 16px;
        line-height: 24px;
    }
    .boxRecursos {
        max-width: 900px;
        padding: 45px;
    }
    .contRecursos h3 {
        font-size: 30px;
        line-height: 40px;
    }
    .contRecursos p {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 30px;
    }
    .contRecursos a, .contRecursosBtn {
        max-width: 330px;
        height: 50px;
        font-size: 18px;
        line-height: 50px;
    }
}

@media screen and (max-width: 1200px) {
    .title-section {
        font-size: 35px;
        line-height: 45px;
    }
    .web-title {
        font-size: 55px;
        line-height: 65px;
        margin-bottom: 55px;
    }
    .imgRecursos {
        width: 200px;
    }
    .contRecursos {
        width: calc(100% - 200px);
    }
}

@media screen and (max-width: 992px) {
    .title-section, .web-title, .contRecursos h3 {
        font-size: 25px;
        line-height: 35px;
    }
    .web-title {
        margin-bottom: 30px;
    }
    .btnSection {
        margin-top: 25px;
    }
    .boxRecursos {
        padding: 35px;
    }
    .contRecursos p {
        font-size: 16px;
        margin-bottom: 25px;
    }
    .contRecursos a, .contRecursosBtn {
        max-width: 230px;
        height: 40px;
        font-size: 16px;
        line-height: 40px;
    }
}

@media screen and (max-width: 767px) {
    .boxRecursos {
        padding: 30px 25px;
    }
    .imgRecursos {
        width: 100px;
    }
    .contRecursos {
        width: calc(100% - 100px);
        padding-left: 20px;
    }
    .contRecursos h3 {
        margin-bottom: 5px;
    }
    .contRecursos p {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 575px) {
    .container{
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
}

@media screen and ( max-width: 440px) {
    .boxRecursos {
        display: block;
    }
    .imgRecursos {
        width: 100%;
        margin-bottom: 15px;
    }
    .imgRecursos img{
        margin: 0px auto;
        display: block;
        max-height: 160px;
    }
    .contRecursos {
        width: 100%;
        padding-left: 0;
    }
}

@media screen and (max-width: 370px) {
    .container{
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    .title-section, .web-title, .contRecursos h3 {
        font-size: 20px;
        line-height: 25px;
    }
    .title-section {
        margin-bottom: 15px;
    }
    .subtext-section, .contRecursos p {
        font-size: 14px;
        line-height: 20px;
    }
    .contRecursos a, .contRecursosBtn{
        font-size: 14px;
    }
}