#main-slider-container {
  width: 100%;
  position: relative;
  display: block;
  margin-top: 110px;
  margin-bottom: 200px;
  overflow: hidden;
}
#main-slider-container .title-section{
  margin-bottom: 70px;
}
#slider {
  width: 100%;
  height: 100%;
  margin-left: 1px;
  margin-right: 1px;
  white-space: nowrap;
  overflow-x: scroll;
  scrollbar-width: none;
  scroll-behavior: smooth;
  max-width: 1400px;
  margin: 0px auto;
}
#slider::-webkit-scrollbar {
  display: none;
}
.slider-card-titulo {
  font-weight: 400;
  font-size: 60px;
  line-height: 72px;
  margin: 0px 0px 45px 0px;
  color: white;
  width: 100%;
}
.slider-card-description {
  width: 100%;
  color: white;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: block;
  position: relative;
  white-space: normal;
  float: left;
}
.cont-btn{
  width: 100%;
  height: auto;
  padding: 0 30px;
  display: flex;
  justify-content: flex-end;
  max-width: 1400px;
  margin: 0px auto;
  margin-top: 20px;
}
.slider-icon.left, .slider-icon.right {
  color: grey;
  position: relative;
  opacity: 0.2;
  box-shadow: 2px 2px 2px 2px rgb transparent;
  cursor: pointer;
}
.slider-icon.left:hover, .slider-icon.right:hover {
  opacity: rgb transparent;
}
.slider-card {
  width: 260px;
  min-height: 502px;
  background: #4052DA;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  padding: 48px 15px 20px 15px;
  position: relative;
}

@media screen and (max-width: 1550px){
  #main-slider-container {
    margin-top: 80px;
    margin-bottom: 100px;
  }
  #main-slider-container .title-section {
    margin-bottom: 55px;
  }
  .slider-card-titulo {
    font-size: 50px;
    line-height: 65px;
    margin: 0px 0px 30px 0px;
  }
  .slider-card {
    min-height: 415px;
  }
  .slider-card-description{
    font-size: 16px;
  }
  .cont-btn svg{
    width: 70px;
    height: 70px;
  }
}

@media screen and (max-width: 1450px){
  #slider, .cont-btn {
    max-width: 1120px;
  }
}

@media screen and (max-width: 1200px){
  #main-slider-container {
    margin-top: 80px;
  }
  #slider, .cont-btn {
    width: 840px;
  }
}

@media screen and (max-width: 992px) {
  #main-slider-container {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  #slider, .cont-btn {
    width: 560px;
  }
  #main-slider-container .title-section {
    margin-bottom: 40px;
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .slider-card {
    min-height: 400px;
  }
  .cont-btn svg {
    width: 50px;
    height: 50px;
  }
}

@media screen and (max-width: 575px) {
  #slider, .cont-btn {
    width: 280px;
  }
  .cont-btn {
    padding: 0;
    margin-top: 0;
  }
}

@media screen and (max-width: 370px) {
  #main-slider-container {
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .slider-card {
    min-height: 360px;
  }
  .slider-card-description {
    font-size: 14px;
  }
}
