.equipo {
    margin-bottom: 120px;
}
.section-equipo .title-section{
    text-align: center;
}
.title-section {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #000;
    margin-bottom: 20px;
    margin-top: 40px;
}
.cards-equipo {
    display: flex;
    justify-content: center;
    margin-top: 40px !important;
}
.cards-equipo .card {
    background-color: rgba(102, 102, 102, 0.13) !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: calc(25% - 20px); 
    min-height: 395px;
    margin: 0 10px 20px 10px;
}
.card-title {
    font-weight: 400 !important;
    font-size: 24px !important;
    line-height: 34px !important;
    color: #4052DA;
    margin-bottom: 30px !important;
}
.card-text {
    color: #000;
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 24px !important;
    margin-bottom: 10px !important;
}
.card-body {
    padding: 37px 12px 30px 12px!important;
}

/* Seccion Encargados de Area */

.cards-equipoAreas{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 40px !important;
}
.contCEA{
    width: calc(100% + 20px);
    position: relative;
    margin-left: -10px;
    margin-right: -10px;
}
.card2{
    background-color: rgba(102, 102, 102, 0.13) !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: calc(20% - 20px); 
    min-height: 330px;
    margin: 0 10px 20px 10px;
}
.card2 .card-body {
    padding-top: 20px !important;
    padding-bottom: 12px !important;
}
.card2 .card-title {
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 24px !important;
    color: #4052DA;
    margin-bottom: 15px !important;
}
.card2 .card-text {
    color: #000;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 22px !important;
    margin-bottom: 10px !important;
}


@media screen and (max-width: 1550px){
    .equipo {
        margin-bottom: 80px;
    }
    .title-section {
        font-size: 30px;
        line-height: 40px;
    }
    .cards-equipo {
        margin-top: 35px !important;
    }
    .card-title {
        font-size: 18px !important;
        line-height: 24px !important;
        margin-bottom: 27px !important;
    }
    .card-body {
        padding-top: 27px !important;
        padding-bottom: 20px !important;
    }
    .card-text {
        font-size: 16px !important;
        line-height: 20px !important;
    }
    .card2 {
        min-height: 315px;
    }
    .cards-equipoAreas{
        margin-top: 35px !important;
    }
    .card2 .card-title{
        font-size: 15px !important;
        line-height: 20px !important;
        margin-bottom: 12px !important;
    }
    .card2 .card-text {
        font-size: 14px !important;
        line-height: 20px !important;
    }
}

@media screen and (max-width: 1200px) {
    .card2 {
        min-height: 285px;
    }
}

@media screen and (max-width: 992px) {
    .equipo {
        margin-bottom: 50px;
    }
    .cards-equipo, .cards-equipoAreas {
        margin-top: 10px !important;
    }
    .cards-equipo .card {
        min-height: 360px;
    }
    .card-title, .card2 .card-title {
        font-size: 16px !important;
        line-height: 20px !important;
        margin-bottom: 12px !important;
    }
    .card-text, .card2 .card-text {
        font-size: 14px !important;
        line-height: 19px !important;
    }
    .card-body, .card2 .card-body {
        padding: 20px 10px 10px 10px !important;
    }
    .cards-equipoAreas .card2 {
        min-height: 220px;
        width: calc(25% - 20px);
    }
}

@media screen and (max-width: 767px) {
    .cards-equipo {
        flex-wrap: wrap;
    }
    .cards-equipo .card {
        width: calc(50% - 20px);
        margin: 0 10px 20px 10px;
    }
    .cards-equipoAreas .card2 {
        width: calc(50% - 20px);
    }
}

@media screen and ( max-width: 370px) {
    .equipo {
        margin-bottom: 40px;
    }
    .cards-equipo .card, .cards-equipoAreas .card2 {
        width: 90%;
        margin-right: 5%;
        margin-left: 5%;
    }
    .contCEA {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }
}
