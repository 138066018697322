
.clientes {
    margin-top: 100px;
}
.cards-clientes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.cards-clientes .card {
    background: none !important;
    margin-right: 20px;
    margin-left: 20px;
    border:0;
    width: 120px;
    margin-bottom: 20px;
}

@media screen and (max-width: 1550px) {
    .cards-clientes .card {
        width: 140px;
    }
}
@media screen and (max-width: 1200px) {
    .clientes {
        margin-top: 50px;
        margin-bottom: 30px;
    }
    .cards-clientes .card {
        width: 120px;
        margin-right: 15px;
        margin-left: 15px;
    }
    .cards-clientes .card {
        width: 140px;
    }
}
@media screen and (max-width: 992px) {
    .cards-clientes .card {
        width: calc(20% - 40px);
        margin-right: 20px;
        margin-left: 20px;
    }
}

@media screen and (max-width: 576px) {
    .cards-clientes .card {
        width: calc(33.33% - 20px);
        margin-right: 10px;
        margin-left: 10px;
    }
}

@media screen and ( max-width: 370px) {
    .clientes {
        margin-top: 40px;
        margin-bottom: 40px;
    } 
}
