.fondo {
  background-color: #4052da;
  padding: 20px 0px 20px 0px;
}
.nav {
  display: flex;
  justify-content: space-between;
  padding: 0;
}
.navbar{
  padding: 0;
}
.navbar-nav {
  color: white;
}
.navbar-brand{
  margin: 0;
  position: relative;
  display: block;
  width: fit-content;
}
.basic-navbar-nav:hover {
  color: white;
}
.navbar .row {
  width: calc(100% + 24px);
}
.linkMenu {
  color: #ffffff !important;
}
.navbar-toggler-icon {
  color: white !important;
}
.menu{
  display: flex;
}
.navbar-nav {
  flex-direction: row;
  margin-left: auto;
  padding-right: 30px;
}
.navbar-nav .linkMenu {
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-right: 12px !important;
  margin-left: 12px !important;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  position: relative;
}
.navbar-nav .linkMenu:before, .navbar-nav .linkMenu:after {
  content: '';
  position: absolute;
  width: 0%;
  height: 2px;
  bottom: -2px;
  background: #fff;
}
.navbar-nav .linkMenu:before {
  left: 0;
}
.navbar-nav .linkMenu:after {
  right: 0;
  background: #fff;
  transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
}
.navbar-nav .linkMenu:hover:before {
  background: #fff;
  width: 100%;
  transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
}
.navbar-nav .linkMenu:hover:after {
  background: transparent;
  width: 100%;
  transition: 0s;
}
.navbar-nav .btnMenu {
  padding: 7px 23px !important;
  margin-right: 0px !important;
  margin-left: 12px !important;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  position: relative;
  background-color: #ffffff;
  height: 40px;
  border-radius: 20px;
  color: #4052DA !important;
  transition: transform .3s
}
.navbar-nav .btnMenu:hover{
  transform: scale(1.1);
}
.alignCenter{
  align-items: center;
}
.titulo {
  color: white;
  font-weight: 700;
  font-size: 60px;
  line-height: 82px;
  margin: 0;
}
.titulo span img{
  max-width: 440px;
  margin:-22px 0 0 10px;
}
.imgDashboard{
  overflow: hidden;
}
.imgDashboard img{
  margin:-80px 0 -60px 0;
}
.btn-close {
  background: none !important;
  z-index: 9;
}
.offcanvas.offcanvas-end{
  background-color: #4052da;
  width: 210px !important;
}
.offcanvas-header svg{
  color: #fff;
  position: absolute;
  left: 25px;
  top: 16px;
}

@media screen and (max-width: 1550px) {
  .navbar-brand img{
    width: 80px;
  }
  .navbar-nav .linkMenu, .navbar-nav .btnMenu{
    font-size: 16px;
  }
}

@media screen and (max-width: 1200px) {
  .titulo{
    font-size: 50px;
    line-height: 70px;
  }
  .titulo span img {
    max-width: 358px;
    margin: -19px 0 0 10px;
  }
}

@media screen and (max-width: 991px) {
  .navbar-brand{
    margin-bottom: 15px;
  }
  .navbar-brand img {
    width: 60px;
  }
  .menu {
    justify-content: flex-end;
    position: relative;
  }
  .navbar-nav {
    flex-direction: column;
    margin-left: auto;
    padding-right: 0;
    align-items: flex-end;
  }
  .navbar-toggler{
    height: 50px;
  }
  .navbar-nav .linkMenu, .navbar-nav .btnMenu {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .titulo {
    font-size: 37px;
    line-height: 50px;
  }
  .titulo span img {
    max-width: 240px;
    margin: -13px 0 0 5px;
  }
  .offcanvas-header {
    padding-top: 35px;
  }
  .imgDashboard img {
    margin: -68px 0 -60px 0;
  }
}

@media screen and (max-width: 767px) {
  .fondo {
    padding: 20px 0px 30px 0px;
  }
  .titulo {
    font-size: 30px;
    line-height: 42px;
  }
  .titulo span img {
    max-width: 205px;
  }
  .imgDashboard img {
    margin: -55px 0 -50px 0;
  }
}

@media screen and (max-width: 575px) {
  .fondo {
    padding: 10px 0px 0px 0px;
  }
  .navbar-brand .linkMenu img{
    height: 45px;
    width: auto;
  }
  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }
  .titulo {
    margin: 0px auto;
    display: block;
    width: 250px;
  }
}

@media screen and (max-width: 370px) {
  .imgDashboard img {
    margin: -35px 0 -30px 0;
  }
}