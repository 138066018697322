.semillero-section, .genb-section {
    padding-top: 120px;
}
.title-section-2{
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    text-transform: uppercase;
    color: #4052DA;
    margin-bottom: 40px;
}
.parrafoRecursos2{
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    max-width: 460px;
    margin: 0px auto;
    margin-bottom: 30px;
    color: #000000;
}
.img-semillero{
    max-width: 470px !important;
}
.img-genb{
    max-width: 200px !important;
}
.img-semillero, .img-genb {
    width: 100%;  
    margin: 0px auto;
    display: block;
    margin-bottom: 15px;
    margin-top: -30px;
}

@media screen and (max-width: 1550px){
    .semillero-section, .genb-section {
        padding-top: 80px;
    }
    .title-section-2 {
        font-size: 30px;
        line-height: 38px;
        margin-bottom: 25px;
    }
    .img-semillero, .img-genb {
        margin-top: -10px;
    }
    .img-semillero {
        max-width: 370px !important;
    }
    .img-genb {
        max-width: 120px !important;
    }
    .parrafoRecursos2 {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 25px;
    }
}

@media screen and (max-width: 992px) {
    .semillero-section, .genb-section {
        padding-top: 50px;
    }
    .title-section-2 {
        font-size: 25px;
        line-height: 35px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 767px) {
    .img-semillero, .img-genb {
        margin-top: 10px;
    }
}

@media screen and (max-width: 370px) {
    .title-section-2 {
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 15px;
    }
    .parrafoRecursos2 {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 15px;
    }
}