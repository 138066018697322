.certificado{
    margin-bottom: 170px;
}
.modal_mask {
    position: fixed;
    height: 100%;
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(3, 4, 1, 0.8);
    opacity:0;
    pointer-events:none;
    -webkit-transition:all 200ms linear;
    transition:all 200ms linear;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .modal_mask:target {
    opacity:1;
    top:0;
    pointer-events:auto;
    z-index:9999;
  }
  .box-modal{
    width: calc(100% - 30px);
    max-width: 750px;
    height: auto;
    border-radius: 10px;
    margin: 30px auto;
    box-shadow: 0 0 1px 0 #818181;
    background-color: #fff;
    padding: 30px;
    position: relative;
    overflow-x: hidden;
  }
  .box-modal a{
    position: absolute;
    font-size: 30px;
    top: 16px;
    right: 20px;
    height: 30px;
    line-height: 30px;
    padding: 0;
    display: block;
  }
  .box-modal img{
    pointer-events: none;
  }


@media screen and (max-width: 1550px){
    .certificado {
        margin-bottom: 100px;
    }
}

@media screen and (max-width: 992px){
    .certificado {
        margin-bottom: 60px;
    }
}

@media screen and (max-width: 575px){
    .box-modal {
        padding: 13px;
    }
    .box-modal a{
        top: 2px;
        right: 5px;
      }
}

@media screen and (max-width: 370px){
    .certificado {
        margin-bottom: 40px;
    }
}