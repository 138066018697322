.container-footer {
  padding: 30px 0 20px 0;
  background-color: #4052da;
  margin-top: 110px;
}
.align-footer{
  align-items: center;
}
.logoFooter{
  max-width: 200px !important;
}
footer a{
  text-decoration: none;
}
footer a, footer a:hover{
  color: #ffffff !important; 
}
.social-icon {
  margin: 0px auto;
  display: block;
  text-align: center;
}
.social-icon a svg{
  transition: all .2s ease-in-out;
  width: 40px;
  height: 40px;
  margin: 0 30px;
}
.social-icon a svg:hover{
  transform: scale(1.1);
}
.container-legal p{
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: right;
  color: #ffffff;
  margin: 0;
}

@media screen and (max-width: 1550px){
  .logoFooter {
    max-width: 120px !important;
  }
  .social-icon a svg {
    width: 30px;
    height: 30px;
    margin: 0 20px;
  }
  .container-legal p {
    font-size: 12px;
    line-height: 16px;
  }
}

@media screen and (max-width:992px) {
  .container-footer {
    margin-top: 60px;
  }
  .logoFooter {
    max-width: 100px !important;
  }
  .social-icon {
    text-align: right;
  }
  .social-icon a svg {
    width: 20px;
    height: 20px;
    margin: 0 0 0 20px;
  }
  .container-legal p {
    text-align: center;
  }
}

@media screen and (max-width: 575px) {
  .container-footer {
    padding: 20px 15px 10px 15px;
    margin-top: 50px;
  }
  .container-legal{
    margin-top: 10px !important;
  }
}