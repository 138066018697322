.section-empresab, .section-desarrollos {
    padding-top: 50px;
}
.section-nosotros {
    padding-top: 80px;
}
.img-empresab {
    width: 190px;
    height: auto;
    margin-top: -25px;
    margin-left: auto;
    display: block;
}
.img-desarrollo img{
    margin-top: -57px;
    margin-bottom: -30px;
}
.btnSection{
    height: 50px;
    padding: 0 25px;
    color: #4052DA;
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: 400;
    font-size: 20px;
    width: fit-content;
    margin-top: 25px;
}
.btnSection img{
    width: 40px;
    margin-right: 10px;
}
.btnSection::after{
    content: '';
    position: absolute;
    width: 48px;
    height: 48px;
    border-radius: 25px;
    left: 0;
    top: 0;
    border: 1px solid #4052DA;
    transition: width 0.3s; 
}
.btnSection:hover::after{
    width: 100%;
}
.section-desarrollos .title-section, .text-right{
    text-align-last: right;
}
#margin{
    margin-top: 50px;
}

@media screen and (max-width: 1550px){
    .btnSection {
        height: 40px;
        padding: 0 20px;
        font-size: 16px;
    }
    .btnSection::after {
        width: 38px;
        height: 38px;
        border-radius: 19px;
    }
    .btnSection img {
        width: 35px;
    }
}

@media screen and (max-width: 1200px){
    .img-desarrollo, .img-nosotros {
        max-width: 100% !important;
        height: auto !important;
    }
    .img-desarrollo img {
        margin-top: -50px;
    }
}

@media screen and (max-width: 992px){
    .img-desarrollo img {
        margin-top: -30px;
    }
    .section-nosotros {
        padding-top: 50px;
    }
}

@media screen and (max-width: 767px) {
    .section-desarrollos .title-section, .text-right {
        text-align-last: left;
    }
    .img-empresab {
        margin: 0px auto;
    }
    .btnSection {
        margin-top: 10px;
        margin: 0px auto;
    }
    .img-desarrollo{
        overflow: hidden;
    }
    .img-desarrollo img {
        margin-top: -70px;
    }
    #margin{
        margin-top: 0px;
    }
}

@media screen and ( max-width: 576px) {
    .desarrollos, .nosotros {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
}

@media screen and ( max-width: 440px) {
    .img-desarrollo img {
        margin-top: -35px;
        margin-bottom: -20px;
    }
}

@media screen and ( max-width: 370px) {
    .section-empresab, .section-desarrollos, .section-nosotros {
        padding-top: 30px;
    }
    .img-empresab {
        margin-top: -20px;
        max-height: 200px;
        width: auto;
    }
}