.misionvision {
    margin-top: 110px;
    background: rgba(176, 195, 255, 0.2);
    padding: 66px 0;
}
.dfVM{
    display: flex;
    justify-content: space-between;
}
.boxVM{
    width: 530px;
    background-color: transparent;
    border: 5px solid #FFFFFF;
    border-radius: 7px;
    padding: 40px;
    transition: all 0.5s;
}
.boxVM:hover{
    background: #FFFFFF;
    box-shadow: 2px 2px 4px 2px rgba(54, 82, 225, 0.19);
}
.boxVM h3{
    font-weight: 500;
    font-size: 40px;
    line-height: 46px;
    color: rgba(0, 0, 0, 0.51);
    margin-bottom: 50px;
    position: relative;
    width: fit-content;
    transition: all 0.5s;
}
.boxVM:hover h3{
    letter-spacing: 5px;
    color: #3652E1;
}
.boxVM h3::after{
    content: '';
    position: absolute;
    width: 27px;
    height: 8px;
    bottom: -10px;
    left: 0;
    background: rgba(0, 0, 0, 0.51);
    transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
}
.boxVM:hover h3::after{
    background: #3652E1;
    width: 100%;
}
.boxVM p{
    font-weight: 300;
    font-size: 28px;
    line-height: 32px;
    color: #000;
    margin: 0;
    text-align: justify;
    text-justify: inter-word;
}


@media screen and (max-width: 1550px) {
    .boxVM {
        width: 450px;
        padding: 35px;
    }
    .boxVM h3{
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 40px;
    }
    .boxVM h3::after{
        height: 4px;
    }
    .boxVM p{
        font-size: 18px;
        line-height: 24px;
    }
}

@media screen and (max-width: 1200px) {
    .misionvision {
        margin-top: 50px;
    }
}

@media screen and (max-width: 992px) {
    .boxVM {
        width: 45%;
        padding: 20px 30px;
    }
    .boxVM h3 {
        font-size: 25px;
        line-height: 35px;
        margin-bottom: 30px;
    }
    .boxVM p{
        font-size: 16px;
    }
}

@media screen and (max-width: 767px) {
    .misionvision {
        padding: 50px 0;
        margin-top: 0;
    }
    .dfVM {
        display: block;
        justify-content: inherit;
    }
    .boxVM {
        width: 100%;
    }
    .boxVM:first-child{
        margin-bottom: 20px;
    }
}

@media screen and ( max-width: 370px) {
    .misionvision {
        padding: 40px 0;
        margin-top: 10px;
    }
    .boxVM {
        padding: 20px;
    }
    .boxVM p {
        font-size: 14px;
        line-height: 20px;
    }
}